import { ref, watch } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useProgramsList() {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Program Name', sortable: true },
    { key: 'date_start', label: 'Start date', sortable: true },
    { key: 'date_end', label: 'Start date', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const filterGenderOptions = ['Boys', 'Girls']
  const searchQuery = ref('')
  const filterDataGender = ref('')
  const filterDataStatus = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterDataGender], () => {
    refetchData()
  })

  const fetchProgramList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value
    if (filterDataGender.value) payloadData.gender = filterDataGender.value

    store
      .dispatch('app-program/fetchProgramList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching programs' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const actionOptions = [
    { value: 'application', title: 'Add Application', link: 'parent-application-add' },
  ]

  return {
    fetchProgramList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    filterGenderOptions,
    searchQuery,
    filterDataGender,
    filterDataStatus,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    actionOptions,

    refetchData,
  }
}
